<template>
    <div class="container-fluid p-dash-cliente-master pb-2 pt-3">
    <BasePageBreadCrumb :pages="pages" title="perfil" :menu="menu"/>
    <div class="col-12 pt-1 px-0">
        <div class="col-12">
            <div class="row"> 
                <div class="col-12 col-md-12 col-lg-9 pt-3">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form  class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Nome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="technical.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}" disabled>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Sobrenome </label>
                                                <input type="text" class="form-control" placeholder="" v-model="technical.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}" disabled>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>CPF </label>
                                                <input type="text" class="form-control" placeholder="000.000.000-00" v-model="technical.cpf" v-validate="'required'" name="cpf" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'" disabled>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>E-mail </label>
                                                <input type="email" class="form-control" placeholder="abc@example.com" v-model="technical.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Whatsapp </label>
                                                <input type="text" class="form-control" placeholder="(99) 9 9999-9999" v-model="technical.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-model="technical.password" ref="password" v-validate="'min:8'" name="password" :class="{'is-invalid': errors.has('password')}">
                                            </div>
                                        </div>
                                        <div class="col-12"></div>
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <div class="form-group">
                                                <label>Confirmar senha </label>
                                                <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="'confirmed:password'" name="confirmed" :class="{'is-invalid': errors.has('confirmed')}">
                                            </div>
                                            <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn ml-2 btn-info">Atualizar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 col-lg-3 px-0 pt-tudoemumsolugar">
                    <BaseBannerDashboard />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TechnicalService from "@/services/resources/TechnicalService";
const service = TechnicalService.build();

export default {
   data(){
       return{
           technical:{
               first_name: null,
               last_name: null,
               cpf: null,
               email: null,
               cellphone: null,
               password: null
           },
           id: null,
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'perfil',
                    to: 'Profile'
                },
            ],
            menu:[]
       }
   },
   computed:{
       user_id(){
           return this.$store.getters.getProfile.id;
       }
   },
   methods:{
       save(){
        this.$validator.validateAll().then((result) => {
            if (result) {
                let technical = Object.assign(this.technical)
                technical.id = this.user_id;

                service
                .update(technical)
                .then(resp => {
                this.$bvToast.toast('Seus dados foram atualizado com sucesso!', {
                    title: 'Técnico atualizado',
                    autoHideDelay: 5000,
                    type: 'success'
                    })
                })
                .catch(err => {
                    this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                        title: 'Criação do técnico',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })
            }
        });
       },
       fetchTechnical(){

        let data = {
            id: this.user_id
        }

        service
        .read(data)
        .then(resp => {
            this.technical = resp;
        })
        .catch(err => {
        console.log(err)
        })

       }
   },
   mounted(){
       this.fetchTechnical();
   }
}
</script>